import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import Content from '../components/Content';
import { Spinner } from '../components/Spinner';

import { Button, Footer, Text, Title } from '../components/styles';
import { STEP_1, STEP_3 } from '../constants/steps';

import { getCreditCardForm, getUnpaidParkings } from '../services/api';
import { scrollToTop } from '../utils';
import PaymentsTable from '../components/PaymentsTable';
import i18n from '../i18n';
import { Helmet } from 'react-helmet';

const Step2 = ({
  userInputLicencePlate,
  countryCode,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [ unpaidParkings, setUnpaidParkings ] = useState(null);
  const [ paymentProviderUrl, setPaymentProviderUrl ] = useState(null);
  const [ paymentProviderUrlError, setPaymentProviderUrlError ] = useState(null);
  const redirectUrl =
  `${window.location.origin}/${countryCode}/${STEP_3}?licencePlate=${encodeURIComponent(userInputLicencePlate)}`;

  useEffect(() => scrollToTop(), []);

  useEffect(() => {
    getUnpaidParkings(userInputLicencePlate, countryCode.toUpperCase())
      .then(parkings => {
        setUnpaidParkings(parkings?.data);
      });
  }, [ userInputLicencePlate, countryCode ]);


  useEffect(() => {
    const loadPayment = async () => {
      getCreditCardForm(userInputLicencePlate, countryCode, i18n.language, redirectUrl)
        .then(data => {
          setPaymentProviderUrl(data.data);
        })
        .catch(error => {
          setPaymentProviderUrlError(error);
        });
    };
    loadPayment();
  }, [ userInputLicencePlate, countryCode ]);

  const cancelHandler = () => {
    history.push( `/${countryCode}/${STEP_1}`);
  };

  const continueHandler = () => {
    if (paymentProviderUrl) {
      window.location.href = paymentProviderUrl;
    }
  };

  function showSpinner() {
    return !unpaidParkings && (!paymentProviderUrl && !paymentProviderUrlError);
  }

  function showUnpaidParkings() {
    return !!(unpaidParkings?.totalCount > 0 && (paymentProviderUrl !== null || paymentProviderUrlError === null));
  }

  function showNoParkings() {
    return unpaidParkings?.totalCount === 0 && (paymentProviderUrl !== null || paymentProviderUrlError === null);
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Title>{t('step2.title')}</Title>
      <Content>
        {paymentProviderUrlError && <Text>{t('step2.connectionError')}</Text>}
        {showSpinner() && <Spinner/>}
        {showUnpaidParkings() &&
          <>
            <Text>{t('step2.infoText')} <b>{userInputLicencePlate}</b>:</Text>
            <PaymentsTable unpaidParkings={unpaidParkings}/>
          </>}
        {showNoParkings() &&
          <Text>{t('step2.noParkingsText')} <b>{userInputLicencePlate}</b></Text>}
      </Content>
      <Footer>
        <Button onClick={cancelHandler}>{t('step2.back')}</Button>
        <Button
          primary
          onClick={continueHandler}
          disabled={!paymentProviderUrl &&
          (!unpaidParkings || unpaidParkings.totalCount === 0)}
        >
          {t('step2.pay')}
        </Button>
      </Footer>
    </>
  );
};

export default withRouter(Step2);


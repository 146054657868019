import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export const Logo = styled.img`
  max-width: 12rem;
  margin-bottom: 4rem;
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  text-align: center;
  color: #00BA4D;
  margin: 1rem 0;
  font-family: "Aimo Sans Bold";
`;

export const Text = styled.p`
  width: 80%;
  max-width: 60rem;
  font-size: 1.2rem;
`;

export const ContentWrapper = styled.main`
  min-height: 16rem;
  width: 100%;
  display: block;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ContentItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

export const Button = styled.button`
  font-family: "Aimo Sans";
  background: ${({ disabled, primary }) => {
    if (disabled) return '#d8d8d8';
    if (primary) return '#00BA4D';
    return 'none';
  }};
  border: ${({ disabled, primary }) => !primary ? `2px solid ${disabled ? '#d8d8d8' : '#fff'}` : '#00BA4D'};
  padding: 0.5em 2.5rem;
  margin: 0.75rem 0.75rem;
  min-width: 9.5rem;
  font-size: 1.2rem;
  text-align: center;
  color: ${({ disabled }) => disabled ? '#696969' : '#fff'};
  border-radius: 1.5em;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const Footer = styled.div`
  flex-direction: "row";
  padding: "1em";
`;

export const Link = styled.a`
  font-family: "Aimo Sans";
  font-size: 0.95rem;
  font-weight: 600;
  color: #7b8ba2;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:after {
  font-family: "Font-Awesome";
  font-weight: 600;
  content: "\f105";
  padding-left: 6px;
  display: inline-block;
  text-decoration: none;
  font-size: 0.6rem;
}
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FacilityInfo from './FacilityInfo';
import { getCountryCodeFromUrl } from '../utils';
import { FINLAND, NORWAY, SWEDEN } from '../constants/CountryCodes';
import { euroCurrencyCode, norwegianKroneCurrencyCode, swedishKronaCurrencyCode } from '../constants/CurrencyCodes';

const Table = styled.table`
  width: 100%;
  max-width: 800px;
  margin: 1rem 2rem;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  line-height: 1.5rem;
  border-bottom: 1pt solid white;
`;

const Td = styled.td`
  text-align: left;
  padding: 0.15rem;
  font-size: 1.1em;
  word-wrap: break-word;
`;

const RightAlignedTd = styled(Td)`
  text-align: right;
  min-width: 100px;
`;

const TotalRowTd = styled.td`
  text-align: right;
  padding: 0.75rem 0.15rem 0.15rem;
  font-size: 1.2em;
`;

const localTimeCountry = countryCode => {
  switch (countryCode) {
  case FINLAND:
    return { iso: 'fi-FI', timeZone: 'Europe/Helsinki' };
  case NORWAY:
    return { iso: 'nb-NO', timeZone: 'Europe/Oslo' };
  case SWEDEN:
    return { iso: 'sv-SE', timeZone: 'Europe/Stockholm' };
  default:
    return { iso: 'en-GB', timeZone: 'Europe/Stockholm' };
  }
};

const getCurrencyCode = countryCode => {
  switch (countryCode) {
  case FINLAND:
    return euroCurrencyCode;
  case SWEDEN:
    return swedishKronaCurrencyCode;
  case NORWAY:
    return norwegianKroneCurrencyCode;
  }
};

const formatLocalDate = (date, countryCode) => new Date(date).toLocaleDateString(localTimeCountry(countryCode).iso);
const formatShortLocalDate = (date, countryCode) => new Date(date).toLocaleTimeString(
  localTimeCountry(countryCode).iso,
  { timeZone: localTimeCountry(countryCode).timeZone, timeStyle: 'short' },
);

const PaymentsTable = ({ unpaidParkings }) => {
  const { t } = useTranslation();
  const countryCode = getCountryCodeFromUrl();
  const currencyCode = getCurrencyCode(countryCode);

  const hasParkTickets = unpaidParkings?.items?.some(item => item.parkTicketId);

  return (
    <Table>
      <thead>
        <TableRow>
          <Td></Td>
          <Td></Td>
          {!hasParkTickets && (
            <>
              <RightAlignedTd>{t('step2.serviceFee')}</RightAlignedTd>
              <RightAlignedTd>{t('step2.parkingFee')}</RightAlignedTd>
            </>
          )}
          <RightAlignedTd>{t('step2.amount')}</RightAlignedTd>
        </TableRow>
      </thead>
      <tbody>
        {unpaidParkings && unpaidParkings.items &&
          unpaidParkings.items.map(({ sessionId, startTime, endTime, parkingZoneUid, price }) => (
            <TableRow key={sessionId}>
              <Td>
                {formatLocalDate(startTime, countryCode)} {formatShortLocalDate(startTime, countryCode)}<br />
                {formatLocalDate(endTime, countryCode)} {formatShortLocalDate(endTime, countryCode)}
              </Td>
              <Td>
                <FacilityInfo parkingZoneUid={parkingZoneUid} />
              </Td>
              {!hasParkTickets && (
                <>
                  <RightAlignedTd>
                    <span>{price.breakdown.serviceFee.amount}</span> <span>{currencyCode}</span>
                  </RightAlignedTd>
                  <RightAlignedTd>
                    <span>{price.breakdown.parkingFee.amount}</span> <span>{currencyCode}</span>
                  </RightAlignedTd>
                </>
              )}
              <RightAlignedTd>
                <span>{price.totalAmount}</span> <span>{currencyCode}</span>
              </RightAlignedTd>
            </TableRow>
          ))
        }
        <TableRow style={{ borderBottom: '0' }}>
          <Td colSpan={hasParkTickets? 1 : 3} />
          <TotalRowTd style={{ textAlign: 'right' }}>
            <b>{t('step2.total')}</b>
          </TotalRowTd>
          <TotalRowTd>
            {unpaidParkings?.sum} {currencyCode}
          </TotalRowTd>
        </TableRow>
      </tbody>
    </Table>
  );
};

export default PaymentsTable;

// NOTE this file is sourced by i18next-scanner and runs in the NodeJS context.

// https://github.com/i18next/i18next-scanner#default-options
module.exports = {
  input: [
    'src/**/*.{js,jsx}',
    // Use ! to filter out files or directories
    '!src/**/*.spec.{js,jsx}',
    '!src/translations/**',
    '!**/node_modules/**',
  ],
  output: './',
  options: {
    debug: true,
    sort: true,
    func: {
      list: [ 'i18next.t', 'i18n.t', 't' ],
      extensions: [ '.js', '.jsx' ],
    },
    trans: {
      component: 'Trans',
      i18nKey: 'i18nKey',
      defaultsKey: 'defaults',
      extensions: [ '.js', '.jsx' ],
    },
    lngs: [ 'en-GB', 'fi', 'no', 'sv' ],
    ns: [
      'common',
    ],
    defaultLng: 'en-GB',
    defaultNs: 'common',
    defaultValue: '',
    resource: {
      loadPath: 'src/translations/{{lng}}.json',
      savePath: 'src/translations/{{lng}}.json',
      jsonIndent: 2,
      lineEnding: '\n',
    },

    // We use the message as the translation key itself so don't parse the key
    nsSeparator: false,
    keySeparator: false,
    contextSeparator: '___',

    interpolation: {
      prefix: '{{',
      suffix: '}}',
    },
  },
};


import { useTranslation } from 'react-i18next';
import { FINLAND, NORWAY, SWEDEN } from '../constants/CountryCodes';
import { getCountryCodeFromUrl } from '../utils';
import React from 'react';
import styled from 'styled-components';
import { useHistory, withRouter } from 'react-router-dom';

const LocationContainer = styled.div`
  font-family: "Aimo Sans Medium";
  font-size: 1.1rem;
  color: #ffffff;
  padding: 2rem 0.5rem;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

function parkingLocation(countryCode, t) {
  switch (countryCode) {
  case FINLAND:
    return t('locationInformation.finland');
  case SWEDEN:
    return t('locationInformation.sweden');
  case NORWAY:
    return t('locationInformation.norway');
  }
}

function ParkingLocation({ countryCode }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLinkClick = e => {
    e.preventDefault();
    history.push('/');
  };

  if (!getCountryCodeFromUrl()) {
    return;
  }

  return (
    <LocationContainer>
      <div>
        {t('locationInformation.prefix')}
        <a style={{ fontWeight: 600 }}> { parkingLocation(countryCode, t) }. </a>
        {t('locationInformation.suffix')}
      </div>
      <a onClick={handleLinkClick} style={{ color: '#00BA4D', cursor: 'pointer' }}>
        {t('locationInformation.clickHere')}</a>
    </LocationContainer>
  );
}

const RoutedLocation = withRouter(ParkingLocation);

export { RoutedLocation };

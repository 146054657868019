import React from 'react';
import Loader from 'react-loader-spinner';

export const SuspenseSpinner = () => (
  <div className='spinner' >
    <Loader type="ThreeDots" color="#00BA4D" height={80} width={80} />
  </div>
);

export const Spinner = () =>
  <Loader type="ThreeDots" color="#00BA4D" height={80} width={80} />
;

import { Link } from './styles';
import React from 'react';
import styled from 'styled-components';
import { FINLAND, NORWAY, SWEDEN } from '../constants/CountryCodes';
import { withRouter } from 'react-router-dom';
import { getCountryCodeFromUrl } from '../utils';
import { useTranslation } from 'react-i18next';

const LinksFooter = styled.footer`
  font-family: "Aimo Sans Medium";
  font-size: 0.95rem;
  font-weight: 600;
  color: #7b8ba2;
  padding: 2rem 0.5rem;
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`;

function getCompanyName(countryCode) {
  switch (countryCode) {
  case FINLAND:
    return 'Aimo Park Finland Oy';
  case SWEDEN:
    return 'Aimo Park Sweden AB';
  case NORWAY:
    return 'Aimo Park Norway AS';
  default:
    return 'Aimo Park Sweden AB';
  }
}

function getPrivacyStatementWebsiteUrl(countryCode, t) {
  switch (countryCode) {
  case FINLAND:
    return t('footer.privacyStatement.finlandWebsite');
  case SWEDEN:
    return t('footer.privacyStatement.swedenWebsite');
  case NORWAY:
    return t('footer.privacyStatement.norwayWebsite');
  }
}

function getTermsAndConditionWebsiteUrl(countryCode, t) {
  switch (countryCode) {
  case FINLAND:
    return t('footer.termsAndCondition.finlandWebsite');
  case SWEDEN:
    return t('footer.termsAndCondition.swedenWebsite');
  case NORWAY:
    return t('footer.termsAndCondition.norwayWebsite');
  }
}

function getContactUsWebsiteUrl(countryCode, t) {
  switch (countryCode) {
  case FINLAND:
    return t('footer.contactUs.finlandWebsite');
  case SWEDEN:
    return t('footer.contactUs.swedenWebsite');
  case NORWAY:
    return t('footer.contactUs.norwayWebsite');
  }
}

function getWebsiteUrl(countryCode, t) {
  switch (countryCode) {
  case FINLAND:
    return t('footer.finlandWebsite');
  case SWEDEN:
    return t('footer.swedenWebsite');
  case NORWAY:
    return t('footer.norwayWebsite');
  }
}

function getWebsiteShortenedUrl(countryCode, t) {
  switch (countryCode) {
  case FINLAND:
    return t('footer.websiteFinlandShort');
  case SWEDEN:
    return t('footer.websiteSwedenShort');
  case NORWAY:
    return t('footer.websiteNorwayShort');
  }
}


function Footer({ countryCode }) {
  const { t } = useTranslation();
  if (!getCountryCodeFromUrl()) {
    return;
  }

  return (
    <LinksFooter>
      <div>&#169; {new Date().getFullYear()} {getCompanyName(countryCode)}</div>
      <div><Link target="_blank" href={getPrivacyStatementWebsiteUrl(countryCode, t)}>
        {t('footer.privacyStatement.text')}</Link></div>
      <div><Link target="_blank" href={getTermsAndConditionWebsiteUrl(countryCode, t)}>
        {t('footer.termsAndCondition.text')}
      </Link></div>
      <div><Link
        target="_blank"
        href={getContactUsWebsiteUrl(countryCode, t)}
      >{t('footer.contactUs.text')}</Link></div>
      <div>
        <Link target="_blank" href={getWebsiteUrl(countryCode, t)}>{getWebsiteShortenedUrl(countryCode, t)}</Link>
      </div>
    </LinksFooter>
  );
}

export default withRouter(Footer);


import axios from 'axios';

const getUnpaidParkings = async (licencePlate, countryCode) => {
  return axios.post('/parkings/unpaids', {
    licensePlate: licencePlate,
    countryCode: countryCode,
  });
};

const getCreditCardForm = async (licensePlate, countryCode, languageCode, redirectUrl) => {
  return await axios.post('/payments/initialize/card_form', {
    licensePlate: encodeURIComponent(licensePlate),
    countryCode: countryCode,
    languageCode: languageCode,
    redirectUrl: redirectUrl,
  });
};

const pay = async (transactionId, licensePlate, countryCode, languageCode) => {
  return axios.post('/payments/pay', {
    transactionId: transactionId,
    licensePlate: encodeURIComponent(licensePlate),
    countryCode: countryCode,
    languageCode: languageCode,
  });
};

const getFacility = async parkingZoneUid => {
  return axios.post('/facility', {
    parkingZoneUid: parkingZoneUid,
  });
};

const sendEmailReceipt = (transactionId, email, language) =>
  axios.post(`/session/receipt`, {
    transactionId: transactionId,
    email: email,
    language: language,
  });

export {
  getUnpaidParkings,
  getCreditCardForm,
  pay,
  sendEmailReceipt,
  getFacility,
};

import React, { useEffect, useState } from 'react';
import { getFacility } from '../services/api';

const FacilityInfo = ({ parkingZoneUid }) => {
  const [ facility, setFacility ] = useState(null);
  const city = facility?.address?.city ? facility?.address?.city : '';
  useEffect(() => {
    getFacility(parkingZoneUid).then(data => {
      setFacility(data.data);
    });
  }, [ parkingZoneUid ]);

  return <>{facility?.name}<br/>{city}</>;
};

export default FacilityInfo;

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Content from '../components/Content';
import { Button, Footer, Text, Title } from '../components/styles';
import { pay, sendEmailReceipt, getUnpaidParkings } from '../services/api';
import { getCountryCodeFromUrl, scrollToTop } from '../utils';
import { Spinner } from '../components/Spinner';
import PaymentsTable from '../components/PaymentsTable';
import i18n from '../i18n';
import { STEP_1 } from '../constants/steps';
import { validEmail } from '../utils';
import { Helmet } from 'react-helmet';

const Form = styled.form`
  margin: 2rem 0;
`;

const Input = styled.input`
  background: transparent;
  border: solid 1px #fff;
  font-size: 1rem;
  color: #fff;
  width: 300px;
  padding: 0.5rem;
  ::placeholder {
    color: #fff;
  }
`;

const Submit = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: #fff;
  border: solid 1px #fff;
  cursor: pointer;
`;

function PaidParkingInfo({ unpaidParkings, licensePlate, t }) {
  let paidParkingCountText = '';

  if (unpaidParkings?.totalCount === 1) {
    paidParkingCountText = t('step3.paidCountSingle');
  }

  if (unpaidParkings?.totalCount > 1) {
    paidParkingCountText = t('step3.paidCountMultiple', { count: unpaidParkings?.totalCount });
  }


  return unpaidParkings.totalCount > 0 ? (
    <>
      <Text>{paidParkingCountText} <b>{licensePlate}</b>:</Text>
      <PaymentsTable unpaidParkings={unpaidParkings}/>
    </>
  ) :
    <Text>{t('step2.noParkingsText')} <b>{licensePlate}</b></Text>
  ;
}

const Step3 = ({ location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [ unpaidParkings, setUnpaidParkings ] = useState(null);
  const [ unpaidParkingError, setUnpaidParkingError ] = useState(null);
  const [ buttonsLoading, setButtonsLoading ] = useState(false);
  const [ markPaidError, setMarkPaidError ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ emailSent, setEmailSent ] = useState(false);
  const [ emailError, setEmailError ] = useState(false);
  const [ emailValidateErr, setEmailValidateErr ] = useState(false);

  const transactionId = queryString.parse(location.search)?.transactionId;
  const licensePlate = queryString.parse(location.search)?.licencePlate;
  const responseCode = queryString.parse(location.search)?.responseCode;
  const paymentCancelled = responseCode === 'Cancel';
  const paymentError = unpaidParkingError || responseCode !== 'OK' && responseCode !== 'Cancel';

  const countryCode = getCountryCodeFromUrl();

  const validateEmail = () => {
    if (!validEmail.test(email)) {
      setEmailValidateErr(true);
      setEmailSent(false);
      return false;
    }
    setEmailValidateErr(false);
    return true;
  };

  useEffect(() => {
    getUnpaidParkings(licensePlate, countryCode.toUpperCase())
      .then(parkings => {
        if (parkings.data.sum === 0 ) {
          history.push('/');
        }
        if (parkings?.data) {
          setUnpaidParkings(parkings?.data);
        }
      }).catch(e => {
        setUnpaidParkingError(e);
      });
  }, [ licensePlate, countryCode ]);


  useEffect(() => {
    const markParkingsAsPaid = async () => {
      if (parkingsFoundAndNoErrors()) {
        setButtonsLoading(true);
        pay(transactionId, licensePlate, countryCode, i18n.language)
          .then(() => {
            setButtonsLoading(false);
          })
          .catch(err => {
            setMarkPaidError(err);
            setButtonsLoading(false);
          });
      }
    };
    markParkingsAsPaid();
  }, [ transactionId, licensePlate, countryCode, unpaidParkings ]);

  useEffect(() => scrollToTop(), []);

  const handleSendEmailReceipt = e => {
    e.preventDefault();
    if (validateEmail()) {
      sendEmailReceipt(transactionId, email, i18n.language)
        .then(res => {
          setEmailSent(res);
          setEmailError(false);
        })
        .catch(err => {
          setEmailError(err);
          setEmailSent(false);
        });
    }
  };

  const continueHandler = () => {
    history.push(`/${countryCode}/${STEP_1}`);
  };

  function parkingsFoundAndNoErrors() {
    return unpaidParkings && (!paymentError && !paymentCancelled);
  }

  function paymentMadeAndNoErrors() {
    return transactionId && (!paymentError && !paymentCancelled);
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Title>{t('step3.title')}</Title>
      <Content>
        {parkingsFoundAndNoErrors() &&
            <PaidParkingInfo unpaidParkings={unpaidParkings} licensePlate={licensePlate} t={t} />}
        {paymentError && <Text>{t('step3.paymentError')}</Text>}
        {paymentCancelled && <Text>{t('step3.paymentCancel')}</Text>}
        {paymentMadeAndNoErrors() && (
          <>
            <Text> {t('step3.emailPrompt')}</Text>
            <Form onSubmit={handleSendEmailReceipt}>
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                name="email"
                placeholder={t('step3.email')}
              ></Input>
              <Submit type="submit">{t('step3.submit')}</Submit>
              {emailValidateErr &&
              <Text style={{ color: 'red', width: '100%' }}>{t('step3.malformedEmail')}</Text>}
              {emailSent && <p>{t('step3.emailSent')}</p>}
              {emailError && <p>{t('step3.emailError')}</p>}
            </Form>
          </>
        )}
      </Content>
      <Footer>
        {buttonsLoading ?
          <Spinner/> :
          (
            <Button primary onClick={continueHandler} disabled={!unpaidParkings || unpaidParkingError}>
              {t('step3.makeNewpayment')}
            </Button>
          )}
        {markPaidError && <Text style={{ color: 'red' }}>{t('step3.markPaidError')}</Text>}
      </Footer>
    </>
  );
};

export default withRouter(Step3);

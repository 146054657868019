import React, { Suspense, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Logo } from './components/styles';
import Stepper from './components/Stepper';

import { SuspenseSpinner } from './components/Spinner';
import CountrySelection from './components/CountrySelector';
import Footer from './components/Footer';
import Step1 from './views/Step1';
import Step2 from './views/Step2';
import Step3 from './views/Step3';
import { STEP_1, STEP_2, STEP_3 } from './constants/steps';
import { LanguageSelector } from './components/LanguageSelector';
import styled from 'styled-components';
import { getCountryCodeFromUrl } from './utils';
import { RoutedLocation } from './components/Location';

const LanguageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    .refreshButton {
      display: none;
    }

    @media screen and (hover: none) and (min-device-aspect-ratio: 1/1) and (orientation: landscape) {
      .refreshButton {
        display: initial;
        float: right;
      }
    }
  `;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top:20px;
  float:right;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Aimo Sans", sans-serif;
  color: #fff;
  text-align: center;
  margin-top: 75px;
  margin-bottom: 25px;
`;

const Main = styled.section`
  flex: 1;
`;

function App() {
  const [ userInputLicencePlate, setUserInputLicencePlate ] = useState('');
  const [ countryCode, setCountryCode ] = useState(getCountryCodeFromUrl());

  return (<Container>
    <LogoContainer>
      <LanguageContainer>
        <LanguageSelector/>
      </LanguageContainer>
    </LogoContainer>
    <Main>
      <Wrapper>
        <Suspense fallback={<SuspenseSpinner/>}>
          <Logo src='../aimopark_easy_logo_retina.png' alt="Aimo Park Easy"/>
          <Switch>

            <Route path={`/:countryCode/${STEP_1}`}>
              <Stepper/>
              <Step1
                setUserInputLicencePlate={setUserInputLicencePlate}
                countryCode={countryCode}
              />
            </Route>

            <Route path={`/:countryCode/${STEP_2}`}>
              <Stepper/>
              <Step2
                userInputLicencePlate={userInputLicencePlate}
                countryCode={countryCode}
              />
            </Route>

            <Route path={`/:countryCode/${STEP_3}`}>
              <Stepper/>
              <Step3/>
            </Route>

            <Route path="/">
              <CountrySelection setCountryCode={setCountryCode}/>
            </Route>

          </Switch>
        </Suspense>
      </Wrapper>
    </Main>
    <RoutedLocation countryCode={countryCode} />
    <Footer countryCode={countryCode}/>
  </Container>
  );
}

App.propTypes = {};

export default App;


export const scrollToTop = () => {
  if (window) {
    window.scrollTo({
      top: 0,
    });
  }
};

export const getCountryCodeFromUrl = () => {
  return location.pathname.split('/')[1];
};

export const getStepNumberCodeFromUrl = () => {
  return location.pathname.split('/')[2];
};

export const validEmail = new RegExp(/\S+@\S+\.\S+/);

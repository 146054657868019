import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextScannerConfig from './i18next-scanner.config';
import { getPersistedLanguage } from './components/LanguageSelector';
import { ENGLISH, FINNISH, NORWEGIAN, SWEDISH } from './constants/LanguageCodes';
import { FINLAND, GREAT_BRITAIN, NORWAY, SWEDEN } from './constants/CountryCodes';

// https://react.i18next.com/guides/quick-start

export const knownLanguages = [
  [ FINNISH ],
  [ SWEDISH ],
  [ ENGLISH ],
  [ NORWEGIAN ],
];

const resources = {};

for (const [ lang ] of knownLanguages) {
  // eslint-disable-next-line no-undef
  resources[lang] = { translation: require(`./translations/${lang}.json`) };
}

const mapToLanguageCode = countryCode => {
  switch (countryCode) {
  case GREAT_BRITAIN:
    return ENGLISH;
  case FINLAND:
    return FINNISH;
  case NORWAY:
    return NORWEGIAN;
  case SWEDEN:
    return SWEDISH;
  default:
    return ENGLISH;
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: mapToLanguageCode(getPersistedLanguage().toLocaleLowerCase()),
    fallbackLng: ENGLISH,
    returnEmptyString: false,

    // keySeparator: i18nextScannerConfig.options.keySeparator, //This was removed so nested keys can be used
    nsSeparator: i18nextScannerConfig.options.nsSeparator,
    contextSeparator: i18nextScannerConfig.options.contextSeparator,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

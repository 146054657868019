import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Content from '../components/Content';
import { Button, Footer, Text, Title } from '../components/styles';
import { scrollToTop } from '../utils';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { STEP_2 } from '../constants/steps';
import { FINLAND, NORWAY, SWEDEN } from '../constants/CountryCodes';
import { Helmet } from 'react-helmet';

const InputContainer = styled.div`
  padding: 0.5em;
  border-radius: 10px;
  background-color: ${props => props.error ? 'red' : '#fff'};
  margin: 1.5em;
  width: 20.5rem;
`;

const Input = styled.input`
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  font-size: 5em;
  text-align: center;
  border: 0 solid #3b53f3;
  border-left-width: 36px;
  border-radius: 3px;
  box-shadow: 0 0 0 2pt #000;
  padding: 0;
  width: 100%;
  height: 5.5rem;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: revert;
`;

const Step1 = ({
  setUserInputLicencePlate,
  countryCode,
}) => {
  const regexGeneralLicensePlate = new RegExp(/^[\w-_.äöåÄÖÅ]{0,10}$/);
  const validateGeneralLicensePlate = text => regexGeneralLicensePlate.test(text);

  const [ error, setError ] = useState(false);
  const [ inputText, setInputText ] = useState('');
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => scrollToTop(), []);

  const continueHandler = () => {
    if (validateGeneralLicensePlate(inputText)) {
      setError(false);
      setUserInputLicencePlate(inputText);
      const location = {
        pathname: `/${countryCode}/${STEP_2}`,
        state: { licencePlate: inputText.replace('-', '') },
      };
      history.push(location);
    } else {
      setError(true);
    }
  };

  const inputHandler = e => {
    const licencePLate = e.target.value;
    if (licencePLate.length > 10) {
      return;
    }
    setInputText(licencePLate.toLocaleUpperCase());
  };

  const getLicensePlatePlaceholder = () => {
    switch (countryCode) {
    case SWEDEN:
      return 'MLB13B';
    case FINLAND:
      return 'ABC012';
    case NORWAY:
      return 'AB12345';
    default:
      return 'ABC012';
    }
  };

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      continueHandler();
    }
  };
  const detectEnvironmentByHostname = () => {
    let environment = undefined;
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      environment = 'dev.';
    } else if (hostname === 'pay-easy.dev.aimopark.io') {
      environment = 'dev.';
    } else if (hostname === 'pay-easy.staging.aimopark.io') {
      environment = 'staging.';
    } else {
      environment = '';
    }

    return environment;
  };
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Title>{t('step1.title')}</Title>
      <Content>
        <Text>{t('step1.thanks')} {t('step1.text')} {t('step1.register')}</Text>
        <InputContainer error={error}>
          <Input
            type="text"
            placeholder={getLicensePlatePlaceholder()}
            value={inputText}
            onChange={inputHandler}
            onKeyPress={onKeyUp}
          ></Input>
        </InputContainer>
        {error && t('step1.incorrectLicenseNumber')}
      </Content>
      <Footer>
        <Button
          primary
          onClick={continueHandler}
          disabled={inputText.length === 0}
        >
          {t('continue')}
        </Button>
        <br />
        <Button
          primary
          onClick={() => {
            window.location.href =
            'https://aimoapp.'+detectEnvironmentByHostname()+'aimopark.io/account/main';
          }
          }
        >
          {t('step1.login')} / {t('step1.createAccount')}
        </Button>
      </Footer>
    </>
  );
};

export default withRouter(Step1);

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { STEP_1, STEP_2, STEP_3 } from '../constants/steps';
import { getStepNumberCodeFromUrl } from '../utils';

const Container = styled.div`
  position: relative;
  display: table;
  width: 50%;
  min-width: 500px;
  table-layout: fixed;
  border-collapse: collapse;
  @media only screen and (max-width: 414px) {
    min-width: 350px;
  }
`;

const Step = styled.div`
  position: relative;
  display: table-cell;
  text-align: center;
  padding: 0.5rem;
  &:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: auto;
    bottom: 1.25rem;
    width: 100%;
    height: 0.125rem;
    background-color: #e3e8ec;
    z-index: 1;
  }
  :last-child:after {
    display: none;
  }
`;

const Label = styled.div`
  font-family: "Aimo Sans Medium", sans-serif;
  font-size: 0.8rem;
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: #fff;
  z-index: 2;

  @media only screen and (max-width: 414px) {
    font-size: 0.7rem;
  }
`;

const Indicator = styled.div`
  position: relative;
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: ${props => props.active ? '#00BA4D' : '#fff'};
  z-index: 2;
`;

const Stepper = () => {
  const currentStep = getStepNumberCodeFromUrl();
  const isCurrentStep = number => number === currentStep;
  const { t } = useTranslation();

  return (
    <Container>
      <Step>
        <Label>{t('start')}</Label>
        <Indicator active={isCurrentStep(STEP_1)} />
      </Step>
      <Step>
        <Label>{t('total')}</Label>
        <Indicator active={isCurrentStep(STEP_2)} />
      </Step>
      <Step>
        <Label>{t('ready')}</Label>
        <Indicator active={isCurrentStep(STEP_3)} />
      </Step>
    </Container>
  );
};

export default Stepper;

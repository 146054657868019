import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import './index.css';
import './responsive.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter>
  <React.StrictMode>
    <App />
  </React.StrictMode>
</BrowserRouter>);



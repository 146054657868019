import React from 'react';
import { ContentItems, ContentWrapper } from './styles';

const Content = ({ children }) => (
  <ContentWrapper>
    <ContentItems>{children}</ContentItems>
  </ContentWrapper>
);

export default Content;

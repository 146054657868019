import { useTranslation } from 'react-i18next';
import React from 'react';
import { ENGLISH, SWEDISH, FINNISH, NORWEGIAN, ENGLISH_WITHOUT_REGION } from '../constants/LanguageCodes';
import { FINLAND, GREAT_BRITAIN, NORWAY, SWEDEN } from '../constants/CountryCodes';
import ReactFlagsSelect from 'react-flags-select';

export function getPersistedLanguage() {
  const mapToCountryCode = languageCode => {
    switch (languageCode) {
    case ENGLISH:
      return GREAT_BRITAIN;
    case FINNISH:
      return FINLAND;
    case NORWEGIAN:
      return NORWAY;
    case SWEDISH:
      return SWEDEN;
    default:
      return GREAT_BRITAIN;
    }
  };

  const language = mapToCountryCode(localStorage.getItem('language')?.toLocaleLowerCase());
  return language.toUpperCase();
}

export function LanguageSelector() {
  const { i18n } = useTranslation();

  const mapToLanguageCode = countryCode => {
    switch (countryCode) {
    case GREAT_BRITAIN:
      return ENGLISH;
    case FINLAND:
      return FINNISH;
    case NORWAY:
      return NORWEGIAN;
    case SWEDEN:
      return SWEDISH;
    default:
      return ENGLISH;
    }
  };

  function persistLanguage(selectedLanguage) {
    const language = mapToLanguageCode(selectedLanguage.toLocaleLowerCase());
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }

  return <ReactFlagsSelect
    selected={getPersistedLanguage().toUpperCase()}
    onSelect={code => persistLanguage(code)}
    countries={[
      GREAT_BRITAIN.toUpperCase(),
      FINLAND.toUpperCase(),
      NORWAY.toUpperCase(),
      SWEDEN.toUpperCase(),
    ]}
    className="menu-flags"
    selectButtonClassName="menu-flags-button"
    customLabels={{
      GB: ENGLISH_WITHOUT_REGION.toUpperCase(),
      FI: FINNISH.toUpperCase(),
      NO: NORWEGIAN.toUpperCase(),
      SE: SWEDISH.toUpperCase() }}
  />;
}


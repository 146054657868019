
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from './styles';
import { STEP_1 } from '../constants/steps';
import { scrollToTop } from '../utils';
import { FINLAND, NORWAY, SWEDEN } from '../constants/CountryCodes';
import { Fi, No, Se } from 'react-flags-select';

const CountrySelection = ({ setCountryCode }) => {
  useEffect(() => scrollToTop(), []);
  const history = useHistory();
  const { t } = useTranslation();

  const countrySelected = countryCode => {
    const location = {
      pathname: `/${countryCode}/${STEP_1}`,
    };
    history.push(location);
    setCountryCode(countryCode);
  };

  return (<><p>{t('country.selectLicensePlate')}</p>
    <Button style={{ display: 'inline-flex', alignItems: 'center' }} onClick={() => countrySelected(FINLAND)}>
      <Fi style={{ paddingRight: '5px' }} /> {t('country.finland')}
    </Button>
    <Button style={{ display: 'inline-flex', alignItems: 'center' }} onClick={() => countrySelected(SWEDEN)} >
      <Se style={{ paddingRight: '5px' }} /> {t('country.sweden')}
    </Button>
    <Button style={{ display: 'inline-flex', alignItems: 'center' }} onClick={() => countrySelected(NORWAY)}>
      <No style={{ paddingRight: '5px' }} /> {t('country.norway')}
    </Button>
  </>);
};

export default CountrySelection;
